import { useState } from 'react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { SubscriptionPlanInterval, SubscriptionPlanStripe } from 'generated/graphql';
import { TextButtonLink } from 'src/components/buttons/text-button';
import RenderClientOnly from 'src/components/render-client-only';
import SubscriptionPlansSelector from 'src/components/subscription-plans-selector';
import { useCouponCode } from 'src/hooks/use-coupon-code';
import useGeoInfo from 'src/hooks/use-geo-info';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { useIsAuthenticated } from 'src/state/auth';
import { useImpressionTracking, trackEvent } from 'src/tracking';
import { useTrackingContext } from 'src/tracking/tracking-context';
import { TranslationKeyCommon } from 'src/types';
import { getPrice } from 'src/utilities/price-helpers';
import { isSupportedSubscriptionPlan, sortSubscriptionPlans } from 'src/utilities/subscription-plan-helpers';
import { getPlanUrl } from 'src/utilities/url-helpers';

type SubscriptionPlansProps = {
  subscriptionPlans: SubscriptionPlanStripe[];
  /**
   * Track plan impressions only once or every time it appears on the screen
   * @default false
   */
  trackImpressionOnlyOnce?: boolean;
  /**
   * Optional custom title translation key
   * @default 'subscription_plan__title'
   */
  titleTranslationKey?: TranslationKeyCommon;
};

/**
 * Helper function to get the subscription plan URL based on the interval, current url, and authentication status
 */
function getSubscriptionPlanUrl(interval: SubscriptionPlanInterval, path: string, isAuthenticated: boolean): string {
  const shouldReturnToThisPage = /^\/audio|video|tv\/connected/.test(path);
  const planUrl = getPlanUrl(interval, shouldReturnToThisPage ? path : undefined);
  return isAuthenticated ? planUrl : `/account/signup?redirect=${encodeURIComponent(planUrl)}`;
}

/**
 * A component containing the subscription plans
 */
export default function SubscriptionPlans({
  titleTranslationKey = 'subscription_plan__title',
  subscriptionPlans: fallbackSubscriptionPlans,
  trackImpressionOnlyOnce = false,
}: SubscriptionPlansProps) {
  const t = useTranslate();
  const sdk = useSdk();
  const isAuthenticated = useIsAuthenticated();
  const { asPath } = useRouter();
  const { couponCode } = useCouponCode();
  // Get the location context where the subscription plans are rendered, for the tracking
  const trackingContext = useTrackingContext();
  const [subscriptionPlanInterval, setSubscriptionPlanInterval] = useState<SubscriptionPlanInterval>(
    SubscriptionPlanInterval.Yearly,
  );
  const { data: geoInfoData } = useGeoInfo();
  const subscriptionPlanUrl = getSubscriptionPlanUrl(subscriptionPlanInterval, asPath, isAuthenticated);

  // Subscription plan data is different per user or coupon code, so we need to re-fetch it from the API on the client side
  // e.g. A user that has trialed before will see different subscription plans than a user that has not trialed before
  const { data: subscriptionPlansData } = useSWR(
    // The key is based on whether the user is authenticated and the coupon code because the subscription plans can differ based on these factors
    ['subscriptionPlans', isAuthenticated ? 'authenticated' : 'unauthenticated', couponCode].filter(Boolean).join('/'),
    () => sdk.subscriptionPlans({ couponCode }),
    {
      fallbackData: {
        __typename: 'RootQueryType',
        subscriptionPlansStripe: {
          __typename: 'SubscriptionPlansStripe',
          plans: fallbackSubscriptionPlans,
        },
      },
    },
  );
  const canPurchaseSubscription = subscriptionPlansData?.subscriptionPlansStripe.plans.some(
    (plan) => plan.isPurchasePossible,
  );
  const subscriptionPlans = subscriptionPlansData?.subscriptionPlansStripe.plans
    .filter(isSupportedSubscriptionPlan)
    .sort(sortSubscriptionPlans);
  const currencyCode = geoInfoData?.currencyCode;
  // Track the user impression of the subscription plans
  const { impressionObserverRef } = useImpressionTracking(
    {
      eventName: 'SubscriptionImpression',
      listName: trackingContext || 'SubscriptionPlans',
      // Use an empty array as a fallback when subscriptionPlans is undefined
      subscriptions: subscriptionPlans || [],
      // Use 'USD' as a fallback when currencyCode is undefined
      currency: currencyCode || 'USD',
    },
    {
      trackImpressionOnlyOnce,
      // Only enable tracking when both subscriptionPlans and currencyCode are defined
      enabled: Boolean(subscriptionPlans && currencyCode),
    },
  );

  function handleChange(value: SubscriptionPlanInterval) {
    const subscriptionPlanStripe = subscriptionPlans?.find((plan) => plan.interval === value);
    const price = currencyCode && subscriptionPlanStripe ? getPrice(subscriptionPlanStripe, currencyCode) : undefined;

    // Update the state with the selected interval
    setSubscriptionPlanInterval(value);

    // Track the user selection of the subscription plan
    if (subscriptionPlanStripe && price) {
      trackEvent({
        eventName: 'SubscriptionClick',
        listName: trackingContext || 'unknown',
        subscription: subscriptionPlanStripe,
        price,
      });
    }
  }

  return (
    <div ref={impressionObserverRef} data-test="subscription-plans">
      <div className="pb-6">
        <h2 className="dg-text-medium-1 pb-4 text-center">{t(titleTranslationKey)}</h2>
        <ul className="flex flex-col gap-1">
          {[t('subscription_plan__feature_releases'), t('subscription_plan__feature_access')].map((text) => (
            <li
              key={text}
              className="dg-text-regular-6 flex flex-row items-center justify-center gap-1.5 text-white text-opacity-70"
            >
              <CheckmarkIcon className="size-6 text-brandYellowC1" aria-hidden />
              {text}
            </li>
          ))}
        </ul>
      </div>
      <SubscriptionPlansSelector
        subscriptionPlans={subscriptionPlans}
        subscriptionPlanInterval={subscriptionPlanInterval}
        currencyCode={currencyCode}
        isPlanEnabled={(plan) => plan.isPurchasePossible}
        onChange={handleChange}
      >
        {canPurchaseSubscription && (
          <RenderClientOnly>
            <TextButtonLink
              href={subscriptionPlanUrl}
              variation="primary"
              className="lg:min-w-48 lg:self-center"
              data-test="subscription-plans-link"
            >
              {t('subscription_plan__submit')}
            </TextButtonLink>
          </RenderClientOnly>
        )}
      </SubscriptionPlansSelector>
    </div>
  );
}
