import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import clsx from 'clsx';
import { SubscriptionPlanInterval, SubscriptionPlanStripe } from 'generated/graphql';
import useIntl from 'src/hooks/use-intl';
import useSession from 'src/hooks/use-session';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';
import { CurrencyCode } from 'src/utilities/currency-code-helpers';
import { getPrice } from 'src/utilities/price-helpers';

type SubscriptionPlanProps = {
  /**
   * The subscription plan to display.
   */
  subscriptionPlan: SubscriptionPlanStripe;
  /**
   * Whether the radio button group is currently in focus
   * @default false
   */
  focus?: boolean;
  /**
   * Whether the plan is currently selected by the user.
   * @default false
   */
  checked?: boolean;
  /**
   * Whether the plan is disabled (e.g. when switching plans is not allowed)
   * @default false
   */
  disabled?: boolean;
  /**
   * The currency code to use for displaying the price.
   */
  currencyCode?: CurrencyCode;
};

// Translation keys for the different subscription plan intervals
const subscriptionPlanTranslationKeys: Record<
  SubscriptionPlanInterval,
  {
    title: TranslationKeyCommon;
    period: TranslationKeyCommon;
    renewal: TranslationKeyCommon;
  }
> = {
  [SubscriptionPlanInterval.Days_7]: {
    title: 'subscription_plan__days_7_title',
    period: 'subscription_plan__days_7_period',
    renewal: 'subscription_plan__days_7_renewal',
  },
  [SubscriptionPlanInterval.Monthly]: {
    title: 'subscription_plan__monthly_title',
    period: 'subscription_plan__monthly_per_period',
    renewal: 'subscription_plan__monthly_renewal',
  },
  [SubscriptionPlanInterval.Yearly]: {
    title: 'subscription_plan__yearly_title',
    period: 'subscription_plan__yearly_per_period',
    renewal: 'subscription_plan__yearly_renewal',
  },
};

// Translation keys and highlight status for the badge based on the subscription plan interval
function getBadgeProps(
  subscriptionPlanInterval: SubscriptionPlanInterval,
  ticketInterval?: SubscriptionPlanInterval,
  highlight?: boolean,
): { translationKey: TranslationKeyCommon; highlight: boolean } | undefined {
  const isCurrentPlan = ticketInterval === subscriptionPlanInterval;
  const isYearlyPlan = subscriptionPlanInterval === SubscriptionPlanInterval.Yearly;
  // If the plan is the current plan, show the "active" badge and highlight it if it's already selected
  if (isCurrentPlan) return { translationKey: 'subscription_plan__badge_active', highlight: highlight || false };
  // If the plan is a yearly plan, show the "yearly" badge and always highlight it
  if (isYearlyPlan) return { translationKey: 'subscription_plan__badge_yearly', highlight: true };
}

/**
 * A component to display a subscription plan.
 * Typically used in a list of subscription plans (e.g. when choosing a subscription).
 */
export default function SubscriptionPlan({
  subscriptionPlan,
  focus = false,
  checked = false,
  disabled = false,
  currencyCode,
}: SubscriptionPlanProps) {
  const t = useTranslate();
  const { currencyFormat } = useIntl();
  const session = useSession();
  const { interval, trialPeriodDays } = subscriptionPlan;
  const price = currencyCode && getPrice(subscriptionPlan, currencyCode);
  const translationKeys = subscriptionPlanTranslationKeys[interval];
  const badgeProps = getBadgeProps(interval, session?.data?.currentUser?.ticket?.interval, checked);

  // define the default values for the badge, renewal and trial period texts
  const badgeText = badgeProps && t(badgeProps.translationKey);
  const renewalText = t(translationKeys.renewal);
  const trialPeriodText = trialPeriodDays > 0 && t('subscription_plan__trial_duration', [trialPeriodDays]);

  return (
    <div
      className={clsx(
        'relative flex h-full select-none flex-row gap-2 rounded border p-3 lg:p-4',
        // use a transparent background when the plan is disabled, a form blue background otherwise
        disabled ? 'bg-transparent' : 'cursor-pointer bg-formBlueC5',
        // use a 15% white border when the plan is disabled, a brand yellow border when checked, and a 35% white border otherwise
        disabled ? 'border-white/15' : checked ? 'border-brandYellowC1' : 'border-white/35',
        focus && 'outline outline-2 outline-offset-2 outline-focusOutline',
      )}
    >
      {/* Checkbox */}
      {!disabled && (
        <div className="size-[18px] shrink-0 lg:absolute lg:right-4 lg:top-4">
          {checked ? (
            <CheckmarkIcon className="size-full rounded-full bg-brandYellowC1 text-darkBlueC7" />
          ) : (
            <div className="size-full rounded-full border border-white/35" />
          )}
        </div>
      )}

      {/* Plan details */}
      <div className="flex flex-1 flex-col gap-2">
        <div className="flex flex-col gap-0.5 lg:pr-6">
          <div className="flex flex-row justify-between gap-2 lg:min-h-11">
            {/* Title */}
            <div className="dg-text-medium-9 text-white">{t(translationKeys.title)}</div>
            {/* Optional badge */}
            {badgeProps && (
              <div
                className={clsx(
                  'dg-text-regular-8 rounded px-2 py-1 uppercase lg:absolute lg:left-4 lg:top-0 lg:-translate-y-1/2',
                  badgeProps.highlight
                    ? 'border border-brand-dg bg-brand-stage text-brand-dg'
                    : 'bg-buttonBlueC8 text-white',
                )}
              >
                {badgeText}
              </div>
            )}
          </div>
          {/* Optional strikethrough price */}
          {price && price.amountUndiscounted > price.amount && (
            <s className="dg-text-regular-6 inline-block lining-nums text-white/55 line-through">
              {currencyFormat(price.amountUndiscounted, price.currency)}
            </s>
          )}
          {/* Price */}
          {price && (
            <div className="dg-text-regular-6 lining-nums text-white">
              {t(translationKeys.period, [currencyFormat(price.amount, price.currency)])}
            </div>
          )}
        </div>
        {/* Plan detail list */}
        <ul className="dg-text-regular-6 text-white/70">
          <li>{renewalText}</li>
          <li>{trialPeriodText}</li>
        </ul>
      </div>
    </div>
  );
}
